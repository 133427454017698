import React, { useState } from "react";
import BimWorkBGImgOne from "../images/bim-services/bim-ws-1.png";
import BimWorkBGImgTwo from "../images/bim-services/bim-ws-2.png";
import BimWorkBGImgThree from "../images/bim-services/bim-ws-3.png";
import BimWorkBGImgFour from "../images/bim-services/bim-ws-4.png";
import BimWorkBGImgFive from "../images/bim-services/bim-ws-5.png";
import BimWorkBGImgSix from "../images/bim-services/bim-ws-6.jpg";

const BimWorkSample = () => {
    const [activeImg, setActiveImg] = useState(0);
    const slides = [
        {
            title: "Bus Terminus Architecture",
            description:
                "The project is a bus terminus in Chennai with a curved architectural design that blends safety and elegance, demonstrating our dedication to innovative infrastructure solutions.",
            bgImage: BimWorkBGImgOne,
        },
        {
            title: "Data Center",
            description:
                "We completed the Amazon data center project, providing architectural design, civil engineering, structural engineering, and BIM modeling services, ensuring efficient and reliable construction and operation.",
            bgImage: BimWorkBGImgTwo,
        },
        {
            title: "IT Park",
            description:
                "We completed the Tidel Park project in Chennai, handling civil engineering, structural engineering, and composite design services.",
            bgImage: BimWorkBGImgThree,
        },
        {
            title: "Shopping Mall",
            description:
                "We are pleased to announce the successful completion of the Mall Development project. Our work included detailed architectural design, robust civil engineering, comprehensive MEP installations, and advanced BIM modeling.",
            bgImage: BimWorkBGImgFour,
        },
        {
            title: "Metro Operation Centre",
            description:
                "The Operations Control Centre project involved architectural design, civil construction, structural engineering, and BIM modeling, culminating in a state-of-the-art facility.",
            bgImage: BimWorkBGImgFive,
        },
        {
            title: "Plant",
            description:
                "We have completed a project involving both plant and office buildings, covering architectural design, civil engineering, structural engineering, and BIM modeling.",
            bgImage: BimWorkBGImgSix,
        },
    ];
    return (
        <div className="slider-wrapper">
            {slides?.map((item, i) => (
                <div
                    key={i}
                    className={`slide ${i === activeImg ? "active" : ""}`}
                    // style={{ backgroundImage: item.bgImage }}
                    style={{ backgroundImage: `url(${item.bgImage})` }}
                    onMouseEnter={() => setActiveImg(i)}
                >
                    <div className="text-container">
                        <h3 className="text-h3 text-clr-white text-fw-medium gap-05x">
                            {item.title}
                        </h3>
                        <p className="text-p4 text-clr-white text-fw-normal">{item.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BimWorkSample;
